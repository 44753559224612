<template>
    <div class="common_content brand_box">
        <!-- <div class="social_title animated fadeInDown">
            PAUL FRANK  <br/><span>大嘴猴</span>
        </div> -->
        <img class="animated bounceInDown" src="../../assets/brand/paul_icon.png"/>
        <div class="s_title_small animated bounceInRight"><span>滚动更多   <span style="color:#EE660E">/</span>   Scroll More</span><img src="../../assets/groupIntro/mouse_icon.gif"/></div>
        <div class="line_b"></div>
        <div class="brand_content">
            <div class="brand_flex">
                <div class="brand_flex_item brand_flex_item2">
                    <div class="brand_title animated fadeInDown">品牌介绍 <span>/ BRAND INTRODUCTION</span></div>
                    <div class="brand_title_sub animated fadeInDown">
                        2016年，美国的潮流先锋品牌Paul Frank（大嘴猴），携手琪尔特集
                        团开启在中国鞋服行业的新旅程，首场以“重塑新生”为主题的2020春
                        夏时装大秀在上海成功举行，短短不到两年时间产品已覆盖成人与儿童两大
                        群体领域。
                    </div>
                    <div class="brand_title animated fadeInDown" id="#1">品牌故事 <span>/ BRAND STORY</span></div>
                    <div class="brand_title_sub animated fadeInDown">
                        1995年，由美国加洲橘子郡起步，至今Paul Frank大嘴猴已经成为一个
                        纵横世界的潮流成功者。 对于每个热爱Paul Frank品牌的潮人来说，
                        Julius不只是一只猴子，更是一种非常个性化的潮流体验。
                        “Paul Frank is your friend”，Paul Frank被视为所有人的朋友，
                        他活泼快乐、自信张扬、值得信赖，对朋友无处不在的陪伴，已然成为当
                        代大小潮人们的最亲密的伙伴。Paul Frank 大嘴猴潮牌用Paul Frank
                        独有的“潮流设计语言”，以魅力超群的原创设计、明快活泼的色彩，
                        给新世代年轻一族带来全心玩潮穿搭。
                    </div>
                </div>
                <div class="brand_flex_item">
                    <img class="brand_img animated bounceInRight delay-c2s" src="../../assets/brand/paul_img_01.png"/>
                </div>
            </div>
        </div>
        <div class="line_b"></div>
        <div class="brand_content">
            <div class="brand_flex">
                <div class="brand_flex_item brand_flex_item_b_1" id="ip_animate">
                    <div class="brand_title" id="#2">IP形象 <span>/ IP IMAGE</span></div>
                    <div class="brand_title_sub brand_title_sub_bottom">
                        <p>英文名：Paul frank</p>
                        <p>中文名：大嘴猴</p>
                        <p>品创：1995年</p>
                        <p>设计师：保罗·弗兰克（Paul frank）</p>
                        <p>风格：原创、复古、时尚</p>
                    </div>
                </div>
                <div class="brand_flex_item brand_flex_item_b_2">
                    <img class="brand_img" id="ip_img_animate" src="../../assets/brand/paul_img_02.png"/>
                </div>
            </div>
        </div>
        <div class="line_b"></div>
        <div class="img_mark_box" id="#3">
            <ul id="fsz_animate">
                
                <li v-for="(item, index) in list" :key="item.category_id" @mouseover="handleOver(index)"   @click="handleToProduct(item.category_id,item)">
                    <div :style="{position: 'relative', left: index === 0 ? '100%' : index === 1 ? '0%' : index === 2 ? '-100%' : '-200%' ,top: '47%'}">
                    <div class="year_1" v-if="isActive === index" style="margin-left: -31px;margin-top: -22px;">{{item.small_title}}</div>
                    <div class="year_2 year_2_2"  v-if="isActive === index">{{item.title}}</div>
                    </div>
                     <transition name="el-zoom-in-center">

                    <span v-show="isActive!==index">
                    
                    <div class="cus_mark"></div>
                    </span>
                     </transition>
                    <img :src="$cndHost+'storage/'+item.image"/>
                </li>
                <!-- <li @mouseover="handleOver(0)"  @click="handleToProduct(104,0)">
                    <transition name="el-zoom-in-center">
                    <span v-show="isActive!==0">
                    <div class="cus_mark"></div>
                    </span>
                    </transition>
                    <img src="../../assets/brand/pal_his_01.png"/>
                </li>
                <li @mouseover="handleOver(1)"   @click="handleToProduct(105,1)">
                    <div class="year_1">{{isActive === 0 ? '2022' : '2021'}}{{isActive === 0 ? '春' : isActive === 1 ? '夏' : isActive === 2 ? '秋' : '冬'}}季</div>
                    <div class="year_2">{{isActive === 0 ? '2022' : '2021'}}</div>
                     <transition name="el-zoom-in-center">

                    <span v-show="isActive!==1">
                    
                    <div class="cus_mark"></div>
                    </span>
                     </transition>
                    <img src="../../assets/brand/pal_his_02.png"/>
                </li>
                <li @mouseover="handleOver(2)"   @click="handleToProduct(106,2)">
                    <div class="year_2 year_2_2">{{isActive === 0 ? 'SPRING' : isActive === 1 ? 'SUMMER' : isActive === 2 ? 'AUTUMN' : 'WINTER'}}</div>
                    <transition name="el-zoom-in-center">
                    <span v-show="isActive!==2">
                    
                    <div class="cus_mark"></div>
                    </span>
                    </transition>
                    <img src="../../assets/brand/pal_his_03.png"/>
                </li>
                <li @mouseover="handleOver(3)"   @click="handleToProduct(107,3)">
                    <transition name="el-zoom-in-center">
                    <span v-show="isActive!==3">
                    <div class="year_2 year_2_3"></div>
                    <div class="cus_mark"></div>
                    </span>
                    </transition>
                    <img src="../../assets/brand/pal_his_04.png"/>
                </li> -->
            </ul>
        </div>
        <div class="line_b"></div>
        <div class="brand_content">
            <div class="brand_flex">
                <div class="brand_flex_item brand_flex_item_b_1">
                    <div class="brand_title" id="bis_animate">品牌形象店 <span>/ BRAND IMAGE STORE</span></div>
                    <div class="brand_title_sub ip_brand_img" id="certify">
                        <!-- <img class="brand_img" src="../../assets/brand/pal_01.png"/>
                        <img class="brand_img" src="../../assets/brand/pal_02.png"/>
                        <img class="brand_img" src="../../assets/brand/pal_03.png"/> -->
                        <swiper class="swiper" ref="mySwiper" :options="swiperOption">
                            <swiper-slide class="custom-slide cus_swiper_slide">
                                <el-image 
                                    :src="img1" 
                                    :preview-src-list="[img1,img2,img3,img4,img5]">
                                </el-image>
                            </swiper-slide>
                            <swiper-slide class="custom-slide cus_swiper_slide">
                                <el-image 
                                    :src="img2" 
                                    :preview-src-list="[img1,img2,img3,img4,img5]">
                                </el-image>
                            </swiper-slide>
                            <swiper-slide class="custom-slide cus_swiper_slide">
                                <el-image 
                                    :src="img3" 
                                    :preview-src-list="[img1,img2,img3,img4,img5]">
                                </el-image>
                            </swiper-slide>
                            <swiper-slide class="custom-slide cus_swiper_slide">
                                <el-image 
                                    :src="img4" 
                                    :preview-src-list="[img1,img2,img3,img4,img5]">
                                </el-image>
                            </swiper-slide>
                            <swiper-slide class="custom-slide cus_swiper_slide">
                                <el-image 
                                    :src="img5" 
                                    :preview-src-list="[img1,img2,img3,img4,img5]">
                                </el-image>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-pagination"></div>
                        <!-- <div class="swiper-button-prev custom_prev_btn" slot="button-prev" @click="prev"></div>
                        <div class="swiper-button-next custom_next_btn" slot="button-next" @click="next"></div> -->
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="line_b"></div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    components: {
      Swiper,
      SwiperSlide
    },
    data() {
        return {
            list: [],
            isActive: 0,
            img1: require('../../assets/brand/PAUL/1.jpg'),
            img2: require('../../assets/brand/PAUL/2.jpg'),
            img3: require('../../assets/brand/PAUL/3.jpg'),
            img4: require('../../assets/brand/PAUL/4.jpg'),
            img5: require('../../assets/brand/PAUL/5.jpg'),
            swiperOption:{
                watchSlidesProgress: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                loop: true,
                loopedSlides: 5,
                autoplay: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    //clickable :true,
                },
                on: {
                    progress: function() {
                        for (let i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i);
                            var slideProgress = this.slides[i].progress;
                            var modify = 1;
                            if (Math.abs(slideProgress) > 1) {
                                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                            }
                            var translate = slideProgress * modify * 260 + 'px';
                            var scale = 1 - Math.abs(slideProgress) / 5;
                            var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                            slide.css('zIndex', zIndex);
                            slide.css('opacity', 1);
                            if (Math.abs(slideProgress) > 3) {
                                slide.css('opacity', 0);
                            }
                        }
                    },
                    setTransition: function(transition) {
                        for (var i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i)
                            slide.transition(transition);
                        }

                    }
                }
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.getScroll);
        this.getList()
    },
    destroyed() {
        window.removeEventListener('scroll', this.getScroll);
    },
    methods:{
         prev(){
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        next(){
            this.$refs.mySwiper.$swiper.slideNext();
        },
        getScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (299 < scrollTop && scrollTop < 600) {
                let dom1 = document.getElementById('ip_animate');
                dom1.classList.add('animated');
                dom1.classList.add('fadeInDown');

                let dom2 = document.getElementById('ip_img_animate');
                dom2.classList.add('animated');
                dom2.classList.add('bounceInRight');
            }else if(699<scrollTop && scrollTop < 1580){
                let dom4 = document.getElementById('fsz_animate');
                dom4.classList.add('animated');
                dom4.classList.add('bounceInRight');
            }else if(scrollTop > 1580){
                let dom3 = document.getElementById('bis_animate');
                dom3.classList.add('animated');
                dom3.classList.add('fadeInDown');
            }
        },
        handleOver(index){
            this.isActive = index;
        },
        handleToProduct(category_id,item) {
            this.$router.push({name: 'product', query:{top_category_id: 100, category_id: category_id,flag: '2',small_title: item.small_title, title: item.title}})
        },
        getList() {
            let cond= {
                params:{
                    id: 101
                }
            };
            this.$http.get(this.$apiUrl.common.categoryExtra,cond).then((res) => {
                this.list = res.list_view_extra
            }).catch(()=>{
            });
        },
        
    },
    watch:{
        $route(){
            let lastIndex = location.href.lastIndexOf('#')
            let domId = location.href.substring(lastIndex)
            document.getElementById(domId).scrollIntoView();
        }
    }
}
</script>
<style scoped>
@import url(../../assets/css/certify.css);
.common_content{
    margin-left: 370px;
    margin-top: 4vh;
}
.brand_box{
    margin-right: 240px;
    margin-top: 100px;
    position: relative;
}
.brand_logo{
position: absolute;
    right: 30px;
    top: 32px;
    width: 150px;
}
.social_title{
font-size: 46px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #000000;
}
.social_title span{
    /* font-size: 25px;
    color: #EE660E; */
}
.line_b{
    height: 2px;
background: #F2F2F2;
margin: 26px 0 26px 0;
}
.s_title_small{
  font-size: 17px;
font-family: AlibabaPuHuiTiL;
font-weight: 400;
color: #000000;
    /* padding-top: 30px; */
        text-align: right;
        margin-top: -26px;
}
.s_title_small img{
      vertical-align: middle;
    margin-left: 5px;
}
.brand_content{
}
.brand_flex{
display: flex;
justify-content: space-between;
}
.brand_flex_item{
    flex: 1;
    position: relative;
}
.brand_flex_item2{
    margin-right: 145px;
}
.brand_img{
    width: 100%;
        margin-top: 142px;
}
.brand_title{
    font-size: 33px;
font-family: AlibabaPuHuiTiB;
font-weight: 400;
color: #221815;
margin-bottom: 35px;
 margin-top: 80px;
 font-weight: bolder;
}
.brand_title span{
    color: #EE660E;
    font-size: 17px;
}
.brand_title_sub{
font-size: 16px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #000000;
line-height: 1.8;
}
.brand_title_sub p{
        margin: 0;
}
.brand_title_sub_bottom{
    position: absolute;
    bottom: 0;
}
.brand_flex_item_b_1{
    flex: 2;
}
.brand_flex_item_b_2{
flex: 3;
}
.img_mark_box{
    
}
.img_mark_box ul{
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.img_mark_box ul li{
    flex: 1;
    position: relative;
    cursor: pointer;
}
.img_mark_box ul li img{
    width: 99%;
    height: 100%;
}
.cus_mark{
        background: rgb(0 0 0 / 70%);
    height: 100%;
    width: 99%;
    position: absolute;
    top: 0;
    left: 0;
}
.year_1{
    font-size: 35px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #FFFFFF;
    position: absolute;
    z-index: 9;
    top: 40%;
    left: 15%;
}
.year_2{
font-size: 121px;
font-family: AlibabaPuHuiTiH;
font-weight: bolder;
color: #FFFFFF;
    position: absolute;
    z-index: 9;
    top: 42%;
    left: 9%;
    border-bottom: 6px solid #ffffff;
}
.year_2_3{
left: 0;
}
.year_2_2{
left: 0;
}
@media screen and (max-width:1500px) {
    .social_title  {
        font-size: 24px;
    }
    .s_title_small  {
        font-size: 14px;
    }
    .s_title_small img  {
        width: 12px;
    }
    .brand_title  {
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 38px;
    font-weight: bolder;
}
.brand_title span  {
    color: #EE660E;
    font-size: 13px;
}
.brand_title_sub  {
    font-size: 13px;
    font-family: AlibabaPuHuiTiR;
    font-weight: 400;
    color: #000000;
    line-height: 1.6;
}
.brand_flex_item2  {
    margin-right: 42px;
}
.brand_box  {
    margin-right: 80px;
    margin-top: 80px;
}
.common_content  {
    margin-left: 340px;
}
.brand_img  {
    width: 100%;
    margin-top: 17px;
}
.brand_content  {
    margin-top: 50px;
}
.brand_logo{
position: absolute;
    right: 24px;
    top: 0px;
    width: 124px;
}
.year_2{
    font-size: 87px;
}
.year_1{
    top: 37%;
    left: 16%;
}
}
.ip_brand_img{
    
}
.ip_brand_img img{
    width: 100%;
    vertical-align: bottom;
    margin-top: 10px;
}
.cus_swiper_slide .big_img{
    width: 59px;
    height: 59px;
    position: absolute;
    right: 0;
}
.delay-c2s{
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}
.swiper-slide:not(.swiper-slide-active){
    filter: blur(5px);
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: -70px;
    left: auto;
    color: #ee660e;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: -70px;
    right: auto;
    color: #ee660e;
}
</style>